<template>
  <perfect-scrollbar>
    <v-app
      class="p-0 m-0 vapp"
      style="height: 100vh"
      transition="fade-transition"
    >
      <v-app-bar color="white" flat app height="80px" class="px-5">
        <router-link :to="'/'" v-slot="{ href, navigate }">
          <a style="z-index: 100" :href="href" @click="navigate">
            <v-img
              src="media/logos/logo_text.png"
              contain
              width="100px"
              height="64px"
            />
          </a>
        </router-link>
        <v-spacer />
        <KTTopbar></KTTopbar>
      </v-app-bar>
      <v-main class="mt-10">
        <v-container>
          <h1 class="text-primary">Contact Details</h1>
          <span v-html="address" />
          <br />
          <h2 class="text-primary">Contact Number</h2>
          {{ phone }}
          <br />
        </v-container>
      </v-main>
    </v-app>
  </perfect-scrollbar>
</template>

<script>
import ApiService from "@/core/services/api.service";
export default {
  name: "contact",
  components: {
    KTTopbar: () => import("@/view/layout/header/Topbar")
  },
  data() {
    return {
      address: "",
      phone: ""
    };
  },
  beforeMount() {
    this.getDetails();
  },
  mounted() {},
  methods: {
    async getDetails() {
      await ApiService.get("settings")
        .then(({ data }) => {
          console.log("data", data);
          this.address = data.data.find(r => {
            return r.key === "hq_address";
          }).value;

          this.phone = data.data.find(r => {
            return r.key === "contact_number";
          }).value;
        })

        .catch(error => {
          this.error = error;
        });
    }
  },
  computed: {}
};
</script>

<style></style>
